/* TODO MOVER TUDO DAQUI PARA BAIXO PARA ARQUIVOS DIFERENTES */

@import url("./assets/style/black-rainboow.animation.css");
@import url("./assets/style/typograph.css");
@import url("./assets/style/heartsanimation.css");
@import url('https://fonts.googleapis.com/css2?family=Funnel+Sans:ital,wght@0,300..800;1,300..800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: "Funnel Sans", "Roboto",
    "Fira Sans", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Funnel Sans", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

html {
  font-size: 18px;
  scroll-behavior: smooth;
}

html.swiper-cube {
  overflow: hidden;
}

.font-funnel {
    font-family: "Funnel Sans", serif;
  }